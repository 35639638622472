import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ValidationService {
  constructor() {}

  numberOnly(e: any, formcontrolname: string, form: FormGroup): void {
    let ctrl = e.target;
    ctrl.value = ctrl.value.replace(/[^0-9]|^ /g, '');
    form.controls[formcontrolname].setValue(ctrl.value);
  }

  numberAndSpecialCharactersOnly(e: any, formcontrolname: string, form: FormGroup): void {
    let ctrl = e.target;

    let check = '^((\\+(\\d{1,3}\\))|\\+\\d{1,3}( )?((\\(\\d{1,3}\\))|\\d{1,3})[- .]?\\d{3,4}[- .]?\\d{4}$';
    if (ctrl.value.match(check) === null) {
      form.controls[formcontrolname].setErrors({ pattern: true });
    }
  }

  alphanumericOnly(e: any, formcontrolname: string, form: FormGroup): void {
    let ctrl = e.target;
    ctrl.value = ctrl.value.replace(/[^A-Za-z0-9- ]|^ /g, '');
    form.controls[formcontrolname].setValue(ctrl.value);
  }

  letterOnly(e: any, formcontrolname: string, form: FormGroup): void {
    let ctrl = e.target;
    ctrl.value = ctrl.value.replace(/[^A-Za-z ]|^ /g, '');
    form.controls[formcontrolname].setValue(ctrl.value);
  }

  description(e: any, formcontrolname: string, form: FormGroup): void {
    let ctrl = e.target;
    ctrl.value = ctrl.value.replace(/[^A-Za-z0-9-,!_@./&*$%^()#\n~ ]|^ /g, '');
    form.controls[formcontrolname].setValue(ctrl.value);
  }

  name(e: any, formcontrolname: string, form: FormGroup): void {
    let ctrl = e.target;
    ctrl.value = ctrl.value.replace(/[^A-Za-z0-9-,!_@./&*$%^()#~ ]|^ /g, '');
    form.controls[formcontrolname].setValue(ctrl.value);
  }

  password(e: any, formcontrolname: string, form: FormGroup): void {
    let ctrl = e.target;
    ctrl.value = ctrl.value.replace(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/);
    form.controls[formcontrolname].setValue(ctrl.value);
  }
  emailpattern(e: any, formcontrolname: string, form: FormGroup): void {
    let ctrl = e.target;
    let check = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
    if (ctrl.value.match(check) === null) {
      form.controls[formcontrolname].setErrors({ email: true });
    }
  }
  wesitepattern(e: any, formcontrolname: string, form: FormGroup): void {
    let ctrl = e.target;
    let check = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    if (ctrl.value.match(check) === null) {
      form.controls[formcontrolname].setErrors({ email: true });
    }
  }
  numberWithDecimal(e: KeyboardEvent, allowDecimal: boolean = true): boolean {
    let ctrl = e.target as HTMLInputElement;
    const value = ctrl.value;
    if (e.key === null) {
      return true;
    }
    if (e.metaKey || e.ctrlKey || e.altKey) {
      return true;
    }
    const specialKeys = [
      'Enter',
      'Backspace',
      'Delete',
      'Tab',
      'Escape',
      'ArrowLeft',
      'ArrowRight',
      'ArrowDown',
      'ArrowUp'
    ];
    if (specialKeys.includes(e.key)) {
      return true;
    }
    const numericKeys = [...(allowDecimal ? '0123456789.' : '0123456789')];
    if (numericKeys.includes(e.key)) {
      const validatekey = allowDecimal ? this.validateDecimal(value, e, 2) : true;
      if (validatekey) {
        return true;
      }
    }
    return false;
  }

  validateDecimal(value: string, event: KeyboardEvent, decimalLength: number = 0): boolean {
    const DECIMALPOINT = '.';
    let dotindex = value.indexOf(DECIMALPOINT);
    if (dotindex > -1) {
      if (event.key === DECIMALPOINT) {
        return false; // Deny after first decimal point
      } else if (value.length - dotindex <= decimalLength) {
        return true;
      } else {
        //All vaildations falied
        //final possible validation is match numeric pattern
        const pattern = /^([0-9]*\.){0,1}[0-9]+/g;
        if (pattern.test(value)) {
          return true;
        }
        return false;
      }
    } else {
      return true; // Allow first decimal point
    }
  }

  returnChanges(e: KeyboardEvent): any {
    const specialKeys = ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];
    if (specialKeys.includes(e.key)) {
      return false;
    }
  }
  returnTrim(e: any, formcontrolname: string, form: FormGroup): void {
    let ctrl = e.target;
    form.controls[formcontrolname].setValue(ctrl.value.trim());
  }
  dateCheck(fromDate: any, toDate: any, formcontrolname: string, form: FormGroup): any {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);
    if (date1.getTime() > date2.getTime()) {
      form.controls[formcontrolname].setValue('');
      form.controls[formcontrolname].setErrors({ min: true });
      return true;
    }
  }
  resetToDate(fromDate: any, toDate: any, formcontrolname: string, form: FormGroup): void {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);
    if (date1.getTime() > date2.getTime()) {
      form.controls[formcontrolname].reset();
    }
  }
}
